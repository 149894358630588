import React, { useState } from "react";
import { navigate } from "gatsby";
import useForm from "react-hook-form";
import axios from "axios";
import { Layout, Loader } from "../../components";
import { BriefNav, Thanks } from "../../components/brief";
import {
  FirstStep,
  SecondStep,
  ThirdStep,
  SubmitStep,
} from "../../components/brief/campaign";
import locales from "../../constants";

const BriefCampaign = ({ location: { pathname } }) => {
  const lang = "en";
  const translation = locales[lang];
  const { register, errors, handleSubmit, triggerValidation, formState } =
    useForm({
      mode: "onChange",
    });
  const [isLoading, setLoading] = useState(false);
  const [step, set] = useState(1);

  const setStep = (step) => {
    set(step);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  const formID = lang === "en" ? "1750" : "1749";

  const onSubmit = (data, e) => {
    if (data.bot_field) return false;
    delete data.bot_field;

    let formData = new FormData();
    formData.set("email", data.email);

    setTimeout(() => {
      setLoading(true);
      axios
        .post("https://adream-mailing.herokuapp.com/send-brief-campaign", data)
        .then(() => {
          axios({
            method: "post",
            url: `https://wp.adream.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(() => {
              setLoading(false);
              navigate("/en/brief-campaign-contact");
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }, 2000);
  };
  const stepsData = {
    step,
    setStep,
    register,
    formState,
    triggerValidation,
    errors,
  };
  return (
    <Layout
      seo={{
        title: "Brief campaign",
        href: pathname,
        lang: "en",
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/brief-kompleksowa-kampania-reklamowa",
      }}
    >
      {isLoading && <Loader />}
      <section className="container-fluid brief_main_section steps">
        <h1>{translation.brief_campaign.step_1_header}</h1>
        <BriefNav currentStep={step} setStep={setStep} steps={4} />
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <input
            type="checkbox"
            name="bot_field"
            ref={register}
            className="bot-field"
          />
          <FirstStep {...stepsData} lang={lang} />
          <SecondStep {...stepsData} lang={lang} />
          <ThirdStep {...stepsData} lang={lang} />
          <SubmitStep {...stepsData} lang={lang} />
          {step === 5 && <Thanks />}
        </form>
      </section>
    </Layout>
  );
};

export default BriefCampaign;
